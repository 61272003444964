import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Layout from "../../components/layout"
import ResetForm from '../../containers/reset'
import { Container } from 'reactstrap'

const Reset = props => {

  return (
    <Layout >
      <Container
        style={{
          padding: '1rem 2rem',
          minHeight: '75vh'
        }}
      >
        <ResetForm props={props}/>

      </Container>
    </Layout>

  )
}

export default Reset
