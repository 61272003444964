import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { Row, Col, Form, FormGroup, FormFeedback, Input, Button, Label } from 'reactstrap'
import { handleReset, isLoggedIn } from '../services/auth'
import { navigate } from 'gatsby';
import { CODE_RESET } from '../components/authcode';
import validator from 'validator'
import formStyles from '../components/styles/form.module.css'
import qs from 'query-string';



export default class ResetForm extends Component {
    constructor(props) {
        super(props);

        const queryData = qs.parse(this.props.props.location.search, { ignoreQueryPrefix: true });

        this.state = {
            isLoading: false,
            password: "",
            confirmPassword: "",
            vercode: queryData.k || "",
            email: queryData.i || "",
            passValid: false,
            confirmValid: false,
            formValid: false,
            respErrorValid: true,
            respErrorMessage: "",
            respSuccess: false,

            formErrors: {
                password: false,
                confirmPassword: false,
            },
            respErrors: {
                password: {
                    isValid: true,
                    message: ""
                }
            },

            expired: false,
            expiry: queryData.t || ""
        };

        // console.log(this.state)
    }

    componentDidMount() {
        if (isLoggedIn()) {
            navigate(`/`)
        }

        // Check the expiry of the Reset Password Link
        let linkOriginExpiry = this.state.expiry
        let linkSetExpiry = new Date().valueOf()
        let diff = linkSetExpiry - linkOriginExpiry

        if (diff > 60e3) {
            var minuteDiff = Math.floor(diff / 60e3)

            if (minuteDiff > 60) {
                this.setState({
                    expired: true
                })
            }
        }


        this.forceUpdate()
    }

    validateField(field, value) {

        let password = this.state.password
        let confirmPassword = this.state.confirmPassword
        let passValid = this.state.passValid
        let confirmValid = this.state.confirmValid
        let fieldValidationMessages = this.state.respErrors
        let fieldValidationErrors = this.state.formErrors



        switch (field) {

            case 'password':
                fieldValidationMessages.password.message = passValid ? '' : undefined;

                if (!validator.matches(value, RegExp('^(?=.*[A-Z])'))) {
                    fieldValidationMessages.password.message = "At least 1 upper case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[a-z])'))) {
                    fieldValidationMessages.password.message = "At least 1 lower case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[!@#$%^&*])'))) {
                    fieldValidationMessages.password.message = "At least 1 symbol is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[0-9])'))) {
                    fieldValidationMessages.password.message = "At least 1 number is required";
                }

                if (!validator.matches(value, RegExp('^(?=.{14,})'))) {
                    fieldValidationMessages.password.message = "Password must have at least 14 characters";
                }

                if (confirmValid) {
                    if (!validator.equals(value, confirmPassword)) {
                        fieldValidationMessages.password.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.password.message = ""
                    }
                }

                passValid = (validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && validator.matches(confirmPassword, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && !validator.isEmpty(value) && !validator.isEmpty(confirmPassword))
                fieldValidationErrors.password = passValid ? false : true;
                break;

            case 'confirmPassword':
                confirmValid = validator.equals(value, password) && !validator.isEmpty(password) && validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})'));
                fieldValidationErrors.confirmPassword = confirmValid ? false : true;
                fieldValidationErrors.password = confirmValid ? false : true;
                fieldValidationMessages.password.message = confirmValid ? "" : "The passwords you entered do not match"
                passValid = confirmValid
                break;

            default:

                break
        }

        this.setState({
            formErrors: fieldValidationErrors,
            passValid: passValid,
            confirmValid: confirmValid,
        }, this.validateForm())

    }

    validateForm() {
        this.setState({
            formValid: this.state.confirmValid && this.state.passValid
        })

    }

    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        },
            () => {
                this.validateField(name, value)
            }
        );

        this.setState({
            respErrorMessage: ''
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        this.setState({
            isLoading: true
        })
        let data = {}

        await handleReset(this.state)
            .then(async (resp) => {
                data = resp
                if (data.isValid) {
                    this.setState({
                        respSuccess: true,
                    })
                } else {
                    switch (data.code) {
                        case CODE_RESET.RESET_EXPIRED:
                            data.message = "expired"
                            break
                        case CODE_RESET.RESET_MISMATCH:
                            data.message = "mismatch"
                            break
                        case CODE_RESET.RESET_PASSWORD:
                            data.message = "You cannot use your previous password. Please create a new one."
                            break
                        default:
                            data.message = "Something went wrong."
                            break;
                    }
                }

                await this.setState({
                    respErrorMessage: data.message,
                    respErrorValid: data.isValid,
                    isLoading: false
                })

            })
            .catch(async (err) => {
                console.log(err)
            })
    }

    render() {
        return (

            <Row  >
                <Col xs={12} sm={12} md={{
                    size: 8,
                    offset: 2
                }}
                >
                    <Form
                        onSubmit={this.handleSubmit}
                        className={formStyles.formContainer}
                    >
                        <h3 style={{
                            fontWeight: 'bolder'
                        }}>
                            Reset Password
                    </h3>
                        <FormGroup>
                            <Input
                                id="email"
                                autoFocus
                                readOnly
                                type="hidden"
                                name="email"
                                value={this.state.email.value}
                            />

                        </FormGroup>

                        <FormGroup>
                            <Label for="password">New Password</Label>
                            <Input
                                id="password"
                                type="password"
                                name="password"
                                autoComplete="off"
                                readOnly={this.state.respSuccess}
                                value={this.state.password.value}
                                onChange={this.handleChange}
                                className={formStyles.signUp}
                                valid={this.state.passValid}
                                disabled={this.state.expired}
                                onDrop={(event) => { event.preventDefault() }}
                                onCopy={(event) => { event.preventDefault() }}
                                onPaste={(event) => { event.preventDefault() }}
                                invalid={this.state.formErrors.password ? true : undefined}
                            />
                            <FormFeedback invalid={this.state.respErrors.password.isValid ? '' : undefined}>
                                {this.state.respErrors.password.message}
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input
                                id="confirmPassword"
                                type="password"
                                name="confirmPassword"
                                autoComplete="off"
                                readOnly={this.state.respSuccess}
                                value={this.state.confirmPassword.value}
                                onChange={this.handleChange}
                                className={formStyles.signUp}
                                valid={this.state.confirmValid}
                                disabled={this.state.expired}
                                onDrop={(event) => { event.preventDefault() }}
                                onCopy={(event) => { event.preventDefault() }}
                                onPaste={(event) => { event.preventDefault() }}
                                invalid={this.state.formErrors.confirmPassword ? true : undefined}
                            />
                            <FormFeedback
                                invalid={this.state.formErrors.confirmPassword ? '' : undefined}
                            >
                                The passwords you entered do not match
                        </FormFeedback>

                        </FormGroup>

                        <FormGroup>
                            <Input
                                id="vercode"
                                type="hidden"
                                name="vercode"
                                value={this.state.vercode.value}
                            />

                        </FormGroup>

                        <FormGroup>
                            <Input
                                type="hidden"
                                invalid={!this.state.respErrorValid || this.state.expired ? true : undefined}
                                valid={this.state.respSuccess}
                            />

                            <FormFeedback valid={this.state.respSuccess} invalid={!this.state.respErrorValid || this.state.expired ? '' : undefined}>
                                {this.state.respErrorValid && !this.state.respSuccess ? '' :
                                    this.state.respErrorMessage === 'expired' ? (
                                        <span>
                                            The verification code you have submitted is already expired. Click <a href="/forgot/">here</a> to request for a new code.
                                    </span>
                                    ) : (
                                            this.state.respErrorMessage === 'mismatch' ? (
                                                <span>
                                                    The verification code you have submitted is invalid. Click <a href="/forgot/">here</a> to request for a new code.
                                        </span>
                                            ) : (
                                                    this.state.respErrorMessage
                                                )

                                        )}
                                {this.state.expired ? (
                                    <p>
                                        All fields have been disabled since the reset password link you are using is already expired. Click <a href="/forgot/">here</a> to request for a new code.
                                </p>
                                ) : null}
                                {this.state.respSuccess ? (
                                    <span>
                                        Password has been successfully reset, click <a href="/">here</a> to go back to the homepage
                                </span>
                                ) : null}
                            </FormFeedback>
                        </FormGroup>

                        <Button
                            className="float-left d-none d-md-block"
                            color={this.state.isLoading || this.state.respSuccess || !(this.state.passValid && this.state.confirmValid) ? 'secondary' : 'primary'}
                            disabled={this.state.isLoading || this.state.respSuccess || !(this.state.passValid && this.state.confirmValid) ? true : undefined}
                        >
                            {this.state.isLoading ? 'Loading...' : 'Submit'}
                        </Button>
                        <Button
                            className="d-md-none"
                            block
                            color={this.state.isLoading || this.state.respSuccess || !(this.state.passValid && this.state.confirmValid) ? 'secondary' : 'primary'}
                            disabled={this.state.isLoading || this.state.respSuccess || !(this.state.passValid && this.state.confirmValid) ? true : undefined}
                        >
                            {this.state.isLoading ? 'Loading...' : 'Submit'}
                        </Button>

                    </Form>
                </Col>
            </Row>
        )
    }
}
